import React from "react";

// Components
import {
    DoctorBio,
    Languages,
    SpecialInterests,
    WeekRoaster,
} from "@components";

const DoctorDetails = ({ pageContext }) => {
    const {
        bio,
        name,
        image,
        interests,
        languages,
        openingHours,
        qualification,
        specialization,
    } = pageContext;

    return (
        <>
            <>
                <DoctorBio
                    name={name}
                    image={image}
                    bio={bio}
                    qualification={qualification}
                    specialization={specialization}
                    interests={interests}
                    languages={languages}
                />
                {openingHours && openingHours.length > 0 && (
                    <WeekRoaster openingHours={openingHours} />
                )}
                {/* {interests && interests.length > 0 && (
                    <SpecialInterests interests={interests} name={name} />
                )}
                {interests && interests.length > 0 && (
                    <Languages languages={languages} />
                )} */}
            </>
        </>
    );
};

export default DoctorDetails;
